import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				BlastZone
			</title>
			<meta name={"description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:title"} content={"BlastZone"} />
			<meta property={"og:description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Marque>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Marque>
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07:08:52.445Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-2.jpg?v=2024-06-12T07%3A08%3A52.445Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07:08:52.465Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-1.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Відчуйте неймовірний адреналін!
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-align="center">
						Вирушайте в незабутню пригоду на BlastZone Paintball, де азарт зустрічається зі стратегією та командною роботою.
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Незалежно від того, чи ви досвідчений гравець, чи новачок, наше сучасне обладнання пропонує неперевершений досвід гри в пейнтбол у безпечному та захоплюючому середовищі. Приєднуйтесь до нас, щоб провести день веселощів і створити спогади на все життя!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="#111828"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
		>
			<Text margin="0px 0px 10px 0px" color="--secondary">
				Наша місія
			</Text>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				Про наш клуб
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					BlastZone Paintball присвячений наданню виняткового пейнтбольного досвіду для гравців усіх рівнів кваліфікації. Завдяки різноманітним ігровим сценаріям і першокласному обладнанню ми гарантуємо, що кожен візит буде наповнений веселощами та захопленнями. Наш експертний персонал захоплюється пейнтболом і прагне зробити ваш досвід приємним і безпечним, зосереджуючись на стратегії, командній роботі та чесній грі.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 0px 0px 25px"
					width="50%"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Strong>
						Що ми пропонуємо?
						<br />
					</Strong>
					<br />
					У BlastZone ми виходимо за рамки звичайного. У нашому клубі є кілька тематичних полів, кожне з яких створено для створення унікальної атмосфери. Від густих лісів до тактичних міських умов, наше середовище ретельно створено, щоб покращити вашу стратегічну гру.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				md-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						2020
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						Відкрили першу локацію
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						400+{" "}
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						задоволених клієнтів
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						5
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						5 локацій
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						20+
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						корпоративних заходів
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Чому BlastZone?
				</Text>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="flex-end"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Динамічні поля: кожна ігрова зона створена для унікального тактичного завдання, яке заохочує стратегічне мислення та командну роботу.{"\n"}
					<br />
					<br />
					- Високоякісне спорядження: ми оснащуємо наших гравців найкращими пейнтбольними маркерами та захисним спорядженням, що забезпечує найкращий досвід.
					<br />
					<br />
					- Керівництво експертів: наші співробітники самі є досвідченими гравцями та пропонують поради, стратегії та підтримку, щоб допомогти вам покращити вашу гру.{"\n"}
					<br />
					<br />
					- Спільнота та події: приєднуйтесь до спільноти ентузіастів! Беріть участь у наших регулярних заходах, турнірах і вечірках лише для учасників.
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Cta>
		<Components.Footer>
			<Override slot="box1" />
			<Override slot="text3" />
			<Override slot="link3" />
			<Override slot="linkBox" />
			<Override slot="box3" />
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="SectionContent" />
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});